import * as PropTypes from "prop-types";

import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/core";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";

import Layout from "../components/layout";
import FrontpageSlug from "../components/frontpageslug";
import Footer from "../components/footer";
import Header from "../components/projectheader";
import Bio from "../components/bio";
import BackgroundImage from "gatsby-background-image";

import styleSelf from "../components/about.module.css";
import Img from "gatsby-image";

export default ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About - No Goblin</title>
        <link rel="canonical" href="https://nogoblin.com" />
        <meta property="og:title" content="About No Goblin" />
        <meta property="og:description" content="Welcome to No Goblin! Your video game rest stop on the information superhighway!" />
        <meta property="og:url" content="https://nogoblin.com/" />
        <meta property="og:image" content="https://nogoblin.com/ms-icon-310x310.png" />
        <meta property="og:site_name" content="No Goblin" />
        <meta property="og:type" content="object" />  
      </Helmet>
      <center>
        <BackgroundImage
          fluid={data.backgroundImg.childImageSharp.fluid}
          title="about"
          className={styleSelf.fakeBackground}
        >


          <div className={styleSelf.downstreamContainer}>


            <div className={styleSelf.capsule + " " + styleSelf.flexContainer}>

              <div >
                <Link to="/"> <Img fixed={data.bigLogo.childImageSharp.fixed} /></Link>
             </div>

              <div className={styleSelf.capsule}>
              <h2>About No Goblin</h2>
                <div className={styleSelf.capsuleSpan}>
                <span>Co-founded by veteran game designers Dan and Panzer in
                2013, No Goblin creates unique games with heart like Roundabout
                and 100ft Robot Golf.</span><br/><br/>
                <span>No Goblin is currently working on No Goblin Game 3. If you'd like to find out more about this game that we haven't announced, be sure to follow the <a href="http://cohost.org/nogoblin">No Goblin Cohost page</a> !</span>
                </div>
              </div>
         
            </div>
            <hr />
            <div className={styleSelf.capsule}>
              <h2>Work with us!</h2>
              <div className={styleSelf.capsuleSpan}>
              We're a remote-first studio with a four day work week. Quality of life as our primary concern, and we schedule and scope our work accordingly. 
              
              <br/><br/>
              </div>
              <div className={styleSelf.flexContainer}>
                {data.allMarkdownRemark.edges.map(({ node }, index) => {
                  if (node.frontmatter.status == "hiring" || node.frontmatter.status == "pending")
                    return (
                      <div key={"hiring" + index}>
                        <Bio node={node} />
                      </div>
                    );
                })}
              </div>
              <br/> 
              <div className={styleSelf.capsuleSpan}>
                &nbsp;
              </div>
              <br/>
            </div>
            <hr />

            <div className={styleSelf.capsule}>
              <h2>Team</h2>
              <div className={styleSelf.flexContainer}>
                {data.allMarkdownRemark.edges.map(({ node }, index) => {
                  if (node.frontmatter.status == "team")
                    return (
                      <div key={"team" + index}>
                        <Bio node={node} />
                      </div>
                    );
                })}
              </div>
            </div>
            <hr />

            <div className={styleSelf.capsule}>
              <h2>Contact us</h2>
              <div className={styleSelf.capsuleSpan}>
                Questions? Thoughts? Let us know at our{" "}
                <a href="http://cohost.org/nogoblin">Cohost page!</a> 
              </div>
            </div>
          </div>
          <Footer />
          </BackgroundImage>
      </center>
    </Layout>
  );
};

export const query = graphql`
  query {
    bigLogo: file(relativePath: { eq: "images/frontpage-logo.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 128, height: 128) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }

    backgroundImg: file(relativePath: { eq: "data/backgrounds/moon.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth:1920, quality:100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: ASC}
    )  {
      edges {
        node {
          html
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            name
            title
            type
            status
            twitter
            summary
            photo {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }

    frontpageProjects: allProjectsJson {
      edges {
        node {
          id
          name
          urlslug
          selltext_short

          frontpage
          frontpage_keyart {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }

          frontpage_logo: logo_long {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
